import React from 'react';
import Jumbotron from '../../Components/jumbotron/index';
// import philosophy1 from '../../assets/img/philosophy/philosophy1.jpeg';
// import philosophy2 from '../../assets/img/philosophy/philosophy2.jpg';
// import philosophy3 from '../../assets/img/philosophy/philosophy3.jpg';

export function JumbotronContainer() {
  const philosophy1 =
    'https://ayushaopus.eu-central-1.linodeobjects.com/171406.245c981e.jpg';
  const philosophy2 =
    'https://ayushaopus.eu-central-1.linodeobjects.com/790242.1137f30d.jpg';
  const philosophy3 =
    'https://ayushaopus.eu-central-1.linodeobjects.com/260615.10dfb944.jpg';
  return (
    <div>
      <div className='Mobile-Container'>
        {/* Jumbotron 1 */}
        <div className='Jumbo'>
          {/* Jumbotron 1 */}
          <div className='philosophy1'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    دائماً ما ينتابني شعور أن خلق وابتكار بيئة رائعة مذهلة
                    للأفراد لأكون محاطاً بهم هو المفتاح الذي يؤدي إلى النجاح
                    وهذا يخلق جنة الأمل. ببساطة أحب أن أرى الناس مستمتعةً بفني
                    الرائع.
                  </p>
                  <p>
                    من الأهداف التي أود تحقيقها التحلي بالشجاعة في تصوري الفني
                    وبذلك أمتلك القدرة على ابتكار عالم خاص بك مصممٌ بأفضل
                    التصاميم المعقدة في الفن المعدني والتصاميم التي تحبها
                    وتستمتع بها.
                  </p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Image
                  src={
                    // 'https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/philosophy1.jpeg'
                    // 'https://ayushaopus.eu-central-1.linodeobjects.com/171406.245c981e.jpeg'
                    philosophy1
                  }
                  alt=''
                />
                <div className='overlay'></div>
              </div>
            </div>
          </div>

          {/* Jumbotron 2 */}
          <div className='philosophy2'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    يمكن الشعور بالطاقة المنبعثة من القطع الفنية المعدنية الضخمة
                    مع مثل هذه المغناطيسية نظراً لأنها تتداخل مع اهتزازك وطاقتك.
                  </p>
                  <p>دع الخيال يصمم محيطك ويخلق شعوراً بالرخاء والغنى.</p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Image
                  src={
                    // 'https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/philosophy2.jpg'
                    // 'https://ayushaopus.eu-central-1.linodeobjects.com/790242.1137f30d.jpg'
                    philosophy2
                  }
                  alt=''
                />
                <div className='overlay'></div>
              </div>
            </div>
          </div>

          {/* Jumbotron 3 */}
          <div className='philosophy3'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    وكذلك أتخصص وأشعر بالفخر بصناعة فنون عامة متطورة وغريبة جداً
                    كي تتمكن المجتمعات من الاستمتاع بمثل هذه الابداعات التي ربما
                    تكون ممكنة خلاف ذلك.
                  </p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Image
                  src={
                    // 'https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/philosophy3.jpg'
                    // 'https://ayushaopus.eu-central-1.linodeobjects.com/260615.10dfb944.jpg'
                    philosophy3
                  }
                  alt=''
                />
                <div className='overlay'></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='Desktop-Container'>
        {/* Jumbotron 1 */}
        <div className='Jumbo'>
          <Jumbotron.Container className='philosophy1'>
            <Jumbotron direction='row'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    دائماً ما ينتابني شعور أن خلق وابتكار بيئة رائعة مذهلة
                    للأفراد لأكون محاطاً بهم هو المفتاح الذي يؤدي إلى النجاح
                    وهذا يخلق جنة الأمل. ببساطة أحب أن أرى الناس مستمتعةً بفني
                    الرائع.
                  </p>
                  <p>
                    من الأهداف التي أود تحقيقها التحلي بالشجاعة في تصوري الفني
                    وبذلك أمتلك القدرة على ابتكار عالم خاص بك مصممٌ بأفضل
                    التصاميم المعقدة في الفن المعدني والتصاميم التي تحبها
                    وتستمتع بها.
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  src={
                    // 'https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/philosophy1.jpeg'
                    // 'https://ayushaopus.eu-central-1.linodeobjects.com/171406.245c981e.jpeg'
                    philosophy1
                  }
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>

          {/* Jumbotron 2 */}
          <Jumbotron.Container className='philosophy2'>
            <Jumbotron direction='row-reverse'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    يمكن الشعور بالطاقة المنبعثة من القطع الفنية المعدنية الضخمة
                    مع مثل هذه المغناطيسية نظراً لأنها تتداخل مع اهتزازك وطاقتك.
                  </p>
                  <p>دع الخيال يصمم محيطك ويخلق شعوراً بالرخاء والغنى.</p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  src={
                    // 'https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/philosophy2.jpg'
                    // 'https://ayushaopus.eu-central-1.linodeobjects.com/790242.1137f30d.jpg'
                    philosophy2
                  }
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>

          {/* Jumbotron 3 */}
          <Jumbotron.Container className='philosophy3'>
            <Jumbotron direction='row'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    وكذلك أتخصص وأشعر بالفخر بصناعة فنون عامة متطورة وغريبة جداً
                    كي تتمكن المجتمعات من الاستمتاع بمثل هذه الابداعات التي ربما
                    تكون ممكنة خلاف ذلك.
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  src={
                    // 'https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/philosophy3.jpg'
                    // 'https://ayushaopus.eu-central-1.linodeobjects.com/260615.10dfb944.jpg'
                    philosophy3
                  }
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>
        </div>
      </div>
    </div>
  );
}
