import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import $ from 'jquery';

// Styled Components
import { Container, Flex } from '../styles/globalStyle';
import { HeaderNav, Logo, Menu } from '../styles/headerStyle';
import { ReactComponent as LogoSVG } from '../assets/svg/logo.svg';
import { ReactComponent as PoloYSVG } from '../assets/svg/AO_Logo_Isotype_RGB-01.svg';

//context
import {
  useGlobalDispatchContext,
  useGlobalStateContext,
} from '../context/globalContext';
//Custom Hook
import useElementPosition from '../hooks/useElementPosition';
import { Link } from 'react-router-dom';

gsap.registerPlugin(ScrollTrigger);
const Header = ({
  onCursor,
  setHamburgerPosition,
  setToggleMenu,
  toggleMenu,
  refs,
  moveHeaderUp,
}) => {
  const dispatch = useGlobalDispatchContext();
  const { currentTheme } = useGlobalStateContext();
  const hamburger = useRef(null);
  const position = useElementPosition(hamburger);

  const toggleTheme = () => {
    if (currentTheme === 'dark') {
      dispatch({ type: 'TOGGLE_THEME', theme: 'light' });
    } else {
      dispatch({ type: 'TOGGLE_THEME', theme: 'dark' });
    }
  };

  const menuHover = () => {
    onCursor('locked');
    setHamburgerPosition({ x: position.x, y: position.y + 72 });
  };

  useEffect(() => {
    window.localStorage.setItem('theme', currentTheme);
  }, [currentTheme]);

  useEffect(() => {
    let scrollPercent;
    $(window).scroll(function () {
      var scroll = $(window).scrollTop(),
        dh = $(document).height(),
        wh = $(window).height();
      scrollPercent = (scroll / (dh - wh)) * 100;
      if (scroll > 50 && moveHeaderUp === true) {
        $('#header').addClass('moveHeaderUp');
      } else {
        $('#header').removeClass('moveHeaderUp');
      }
    });
  }, []);

  return (
    <HeaderNav
      id='header'
      // className={window.scrollY > 200 && moveHeaderUp ? 'moveHeaderUp' : null}
      animate={{ y: 0, opacity: 1 }}
      initial={{ y: -72, opacity: 0 }}
      transition={{
        duration: 1,
        ease: [0.6, 0.05, -0.01, 0.9],
      }}
    >
      <Container>
        <Flex spaceBetween noHeight>
          <Logo
            onMouseEnter={() => onCursor('hovered')}
            onMouseLeave={onCursor}
          >
            <Link to='/'>
              <LogoSVG
                onMouseEnter={onCursor}
                onMouseLeave={onCursor}
                ref={refs}
              ></LogoSVG>
            </Link>
            <PoloYSVG
              className={'poloySVG'}
              onClick={toggleTheme}
              onMouseEnter={() => onCursor('pointer')}
              onMouseLeave={onCursor}
            />
            {/*<Link to="/">FURR</Link>
                        <span
                            onClick={toggleTheme}
                            onMouseEnter={() => onCursor("pointer")}
                            onMouseLeave={onCursor}
                        ></span>
                        <Link to="/">W</Link>*/}
          </Logo>
          <Menu
            onClick={() => setToggleMenu(!toggleMenu)}
            ref={hamburger}
            onMouseEnter={menuHover}
            onMouseLeave={onCursor}
          >
            <button>
              <span></span>
              <span></span>
              <span></span>
            </button>
          </Menu>
        </Flex>
      </Container>
    </HeaderNav>
  );
};

export default Header;
