import React, { useEffect } from 'react';
import {
  FullWidth,
  ProjectContentWrapper,
} from '../styles/Project/ProjectContentStyles';
import ProjectBanner from '../Components/Project/ProjectBanner';
import ProjectContent from '../Components/Project/ProjectContent';
import Form from '../Components/Form';
import { Container } from '../styles/globalStyle';
import { JumbotronContainer } from '../containers/contact/index';
import {
  HomeAccordian,
  CopyrightAccordion,
} from '../Components/Homepage/HomeAbout';
import { analytics } from '../firebase/index';

const Contact = ({ onCursor, toggleMenu, setToggleMenu }) => {
  useEffect(() => {
    analytics.logEvent('Contact_Page_visited');
    setToggleMenu(false);
  }, []);

  return (
    <div>
      <ProjectBanner
        title={'التواصل'}
        noFeaturedVideo={true}
        toggleMenu={toggleMenu}
        setToggleMenu={setToggleMenu}
        onCursor={onCursor}
      />

      {/* <ProjectContent credits={false} content={content} /> */}
      <JumbotronContainer />
      <ProjectContentWrapper>
        <Container>
          <Form onCursor={onCursor} />
        </Container>
      </ProjectContentWrapper>
      <HomeAccordian page='contact' onCursor={onCursor} />
      {/* <CopyrightAccordion onCursor={onCursor} /> */}
    </div>
  );
};
export default Contact;
