/* eslint-disable */

import React, { useEffect } from 'react';
import { FullWidth } from '../styles/Project/ProjectContentStyles';
import ProjectBanner from '../Components/Project/ProjectBanner';
import ProjectContent from '../Components/Project/ProjectContent';
import Links from '../Components/Shared/Links';
import { EctraSpace } from '../styles/globalStyle';
import { JumbotronContainer } from '../containers/commitment/index';
import {
  HomeAccordian,
  CopyrightAccordion,
} from '../Components/Homepage/HomeAbout';
import { analytics } from '../firebase/index';

const Commitment = ({
  onCursor,
  toggleMenu,
  setToggleMenu,
  setLinkSection,
}) => {
  useEffect(() => {
    analytics.logEvent('Commitment_Page_visited');
    setToggleMenu(false);
  }, []);
  return (
    <div>
      <ProjectBanner
        title='الالتزام '
        page='Commitment'
        noFeaturedVideo={true}
        toggleMenu={toggleMenu}
        setToggleMenu={setToggleMenu}
        onCursor={onCursor}
      />
      {/* <ProjectContent credits={false} content={content} /> */}
      <JumbotronContainer />

      <Links setLinkSection={setLinkSection} onCursor={onCursor} />
      <HomeAccordian onCursor={onCursor} />
      {/* <CopyrightAccordion onCursor={onCursor} /> */}
    </div>
  );
};

export default Commitment;
