import React, { useEffect } from 'react';
import { FullWidth } from '../styles/Project/ProjectContentStyles';
import ProjectBanner from '../Components/Project/ProjectBanner';
import ProjectContent from '../Components/Project/ProjectContent';
import Links from '../Components/Shared/Links';
import { JumbotronContainer } from '../containers/beauty/index';
import {
  HomeAccordian,
  CopyrightAccordion,
} from '../Components/Homepage/HomeAbout';
import { analytics } from '../firebase/index';

const Beauty = ({ onCursor, toggleMenu, setToggleMenu, setLinkSection }) => {
  useEffect(() => {
    analytics.logEvent('Beauty_Page_visited');
    setToggleMenu(false);
  }, []);

  return (
    <div>
      <ProjectBanner
        title={'الجمال'}
        noFeaturedVideo={true}
        toggleMenu={toggleMenu}
        setToggleMenu={setToggleMenu}
        onCursor={onCursor}
      />
      <JumbotronContainer />

      <Links setLinkSection={setLinkSection} onCursor={onCursor} />
      <HomeAccordian onCursor={onCursor} />
      {/* <CopyrightAccordion onCursor={onCursor} /> */}
    </div>
  );
};

export default Beauty;
