import React from 'react';
import Jumbotron from '../../Components/jumbotron/index';
import useWindowSize from '../../hooks/useWindowSize';
// import commitment1 from '../../assets/img/commitment/commitment1.jpg';
// import commitment2 from '../../assets/img/commitment/commitment2.jpg';

export function JumbotronContainer() {
  const commitment1 =
    'https://ayushaopus.eu-central-1.linodeobjects.com/5643218.f88b6e0c.jpg';
  const commitment2 =
    'https://ayushaopus.eu-central-1.linodeobjects.com/1590834.162876c1.jpg';

  const size = useWindowSize();
  return (
    <>
      <div className='Desktop-Container'>
        <div className='Jumbo'>
          {/* Jumbotron 1 */}
          <Jumbotron.Container className='commitment1'>
            <Jumbotron direction='row'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    يمتاز العمل الذي نقوم به بأنه مفصل جداً، ثقيلٌ للغاية، يتطلب
                    أيدي عاملة كثيرة، ويحتاج إلى ساعات هائلة لإنجازه على الوجه
                    المطلوب؛ يمكنك تخيل بعض من هذه القطع التي سوف يبلغ وزنها
                    أكثر من 2000 رطل بناءً على الحجم والمعدن المستخدم.
                  </p>
                  <p>
                    سوف يحمل تشكيل وابداع هذه القطع الفنية معانٍ ذات مغزى فضلاً
                    عن شراء قطعة فنية عصرية نادرة؛ بالإضافة إلى تأثيرها الإيجابي
                    على الأشخاص الفقراء قليلي الحظ من حول العالم، وهذا بدوره سوف
                    يساعدنا على توفير الطعام لهؤلاء الفقراء....
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              {size.width < 1000 ? (
                <Jumbotron.Pane>
                  <Jumbotron.Text>
                    <p>جنباً إلى جنب!</p>
                  </Jumbotron.Text>
                </Jumbotron.Pane>
              ) : null}
              <Jumbotron.Pane>
                <Jumbotron.Image
                  src={
                    // 'https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/commitment1.jpg'
                    // 'https://ayushaopus.eu-central-1.linodeobjects.com/5643218.f88b6e0c.jpg'
                    commitment1
                  }
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>

          {/* Jumbotron 2 */}
          <Jumbotron.Container className='commitment2'>
            <Jumbotron direction='row'>
              <Jumbotron.Pane>
                <Jumbotron.Text
                  className={size.width < 1000 ? 'displayNone' : ''}
                >
                  <p>جنباً إلى جنب!</p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    أؤمن بأن الحياة عبارة عن فن رسم دون ممحاة؛ لذا دع خيارات
                    حياتنا تخلق وتبدع الجمال لبعضنا البعض.
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>

          {/* Jumbotron 3 */}
          <Jumbotron.Container className='commitment3'>
            <Jumbotron direction={size.width > 1000 ? 'row' : 'row-reverse'}>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  src={
                    // 'https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/commitment2.jpg'
                    // 'https://ayushaopus.eu-central-1.linodeobjects.com/1590834.162876c1.jpg'
                    commitment2
                  }
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    بالرغم من أن جميع أعمالي الفنية محدودة ونادرة ولوحات فنية
                    فريدة من نوعها، قد أميل من خلال طلبات خاصة بصناعة بعض
                    الأعمال الفنية المفصلة – محددة فقط بحدود خيالك وأبث فيها
                    الحياة.
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>

          {/* Jumbotron 4 */}
          <Jumbotron.Container className='commitment4'>
            <Jumbotron direction='row'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    جميع حقوق النشر لكافة تصاميم اللوحات الفنية الأصلية محفوظة
                    كملكية فكرية.
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>
        </div>
      </div>

      <div className='Mobile-Container'>
        <div className='Jumbo'>
          {/* Jumbotron 1 */}
          <div className='commitment1'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    يمتاز العمل الذي نقوم به بأنه مفصل جداً، ثقيلٌ للغاية، يتطلب
                    أيدي عاملة كثيرة، ويحتاج إلى ساعات هائلة لإنجازه على الوجه
                    المطلوب؛ يمكنك تخيل بعض من هذه القطع التي سوف يبلغ وزنها
                    أكثر من 2000 رطل بناءً على الحجم والمعدن المستخدم.
                  </p>
                  <p>
                    سوف يحمل تشكيل وابداع هذه القطع الفنية معانٍ ذات مغزى فضلاً
                    عن شراء قطعة فنية عصرية نادرة؛ بالإضافة إلى تأثيرها الإيجابي
                    على الأشخاص الفقراء قليلي الحظ من حول العالم، وهذا بدوره سوف
                    يساعدنا على توفير الطعام لهؤلاء الفقراء....
                  </p>
                </Jumbotron.Text>
              </div>
              {size.width < 1000 ? (
                <div>
                  <Jumbotron.Text>
                    <p>جنباً إلى جنب!</p>
                  </Jumbotron.Text>
                </div>
              ) : null}
              <div>
                <Jumbotron.Image
                  src={
                    // 'https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/commitment1.jpg'
                    // 'https://ayushaopus.eu-central-1.linodeobjects.com/5643218.f88b6e0c.jpg'
                    commitment1
                  }
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </div>
            </div>
          </div>

          {/* Jumbotron 2 */}
          <div className='commitment2'>
            <div>
              <div>
                <Jumbotron.Text
                  className={size.width < 1000 ? 'displayNone' : ''}
                >
                  <p>جنباً إلى جنب!</p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Text>
                  <p>
                    أؤمن بأن الحياة عبارة عن فن رسم دون ممحاة؛ لذا دع خيارات
                    حياتنا تخلق وتبدع الجمال لبعضنا البعض.
                  </p>
                </Jumbotron.Text>
              </div>
            </div>
          </div>

          {/* Jumbotron 3 */}
          <div className='commitment3'>
            <div direction={size.width > 1000 ? 'row' : 'row-reverse'}>
              <div>
                <Jumbotron.Image
                  src={
                    // 'https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/commitment2.jpg'
                    // 'https://ayushaopus.eu-central-1.linodeobjects.com/1590834.162876c1.jpg'
                    commitment2
                  }
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </div>
              <div>
                <Jumbotron.Text>
                  <p>
                    بالرغم من أن جميع أعمالي الفنية محدودة ونادرة ولوحات فنية
                    فريدة من نوعها، قد أميل من خلال طلبات خاصة بصناعة بعض
                    الأعمال الفنية المفصلة – محددة فقط بحدود خيالك وأبث فيها
                    الحياة.
                  </p>
                </Jumbotron.Text>
              </div>
            </div>
          </div>

          {/* Jumbotron 4 */}
          <div className='commitment4'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    جميع حقوق النشر لكافة تصاميم اللوحات الفنية الأصلية محفوظة
                    كملكية فكرية.
                  </p>
                </Jumbotron.Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
