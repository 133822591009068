import styled from 'styled-components/macro';

export const LinksSection = styled.div`
  margin: 6rem 0;
  padding: 2rem;
  // background: ${(props) => props.theme.red};
  background: linear-gradient(
    to right,
    #9c192f,
    5%,
    #eb002a,
    20%,
    #aa0034,
    80%,
    #eb002a,
    95%,
    #b1212b
  );

  @media (max-width: 768px) {
    margin: 4rem 0;
    padding: 1rem;
    .padding {
      padding: 0 10px;
    }
    .flex {
      flex-direction: row;
    }
  }

  @media (max-width: 500px) {
    margin: 4rem 0;
    padding: 1rem;
    .padding {
      padding: 0 10px;
    }
    .flex {
      flex-direction: row;
    }
  }

  a {
    font-weight: 300;
    font-size: 3.4rem;
    // color: ${(props) => props.theme.text};
    color: #ffffff;

    @media (max-width: 768px) {
      font-size: 2rem;
      margin: 0 15px;
    }

    @media (max-width: 500px) {
      font-size: 1.5rem;
      margin: 0 -5px;
    }
  }
`;
