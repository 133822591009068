import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

//Styled Component
import { createGlobalStyle, ThemeProvider } from 'styled-components/macro';
import { normalize } from 'styled-normalize';

//Context
import {
  useGlobalStateContext,
  useGlobalDispatchContext,
} from '../context/globalContext';
import CustomCursor from './CustomCursor';
import Header from './Header';
import Navigation from './Navigation';
import Footer from './Footer';

gsap.registerPlugin(ScrollTrigger);
const GlobalStyle = createGlobalStyle`
  ${normalize}
  * {
    text-decoration: none;
    cursor: none;
  }

  html {
    box-sizing: border-box;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
  }

  body {
    font-family: 'Lato', sans-serif;
    background: ${(props) => props.theme.background};
    overscroll-behavior: none;
    overflow-x: hidden;
  }

  /*  @font-face {
      font-family: 'Caviar Dreams';
      font-style: normal;
      font-weight: 300;
      src: local('Caviar Dreams'), url('../assets/fonts/CaviarDreams.tff') format('woff');
    }*/


  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #6d6d6d;
  }

  ::-webkit-scrollbar {
    width: 2px;
    @media (max-width: 768px) {
      width: 0px;
      display: none;
      }
  }

  ::-webkit-scrollbar-track {
    background-color: #4e555b;
  }
`;

const Layout = ({
  children,
  toggleMenu,
  setToggleMenu,
  linkSection,
  moveHeaderUp,
}) => {
  useEffect(() => {
    gsap.to(logoRef.current, { rotation: 719 });
    const tl = gsap
      .timeline({
        scrollTrigger: {
          trigger: 'main',
          scrub: 0.2,
        },
      })
      .to(logoRef.current, {
        rotation: 360 * 5,
        duration: 1,
        ease: 'none',
      });
  }, []);
  const [hamburger, setHamburgerPosition] = useState({
    x: 0,
    y: 0,
  });

  const logoRef = useRef();
  const darkTheme = {
    background: '#000',
    text: '#fff',
    red: '#f42835',
    // red: 'linear-gradient(to right,#9c192f,5%,#f42835,20%,#aa0034,80%,#eb002a,95%,#b1212b)',
    left: `${hamburger.x}px`,
    top: `${hamburger.y}px`,
  };
  const lightTheme = {
    text: '#000',
    background: '#fff',
    red: '#f42835',
    left: `${hamburger.x}px`,
    top: `${hamburger.y}px`,
  };
  const { currentTheme, cursorStyles } = useGlobalStateContext();
  const dispatch = useGlobalDispatchContext();
  // const moveHeaderUp = false;

  const onCursor = (cursorType) => {
    cursorType = (cursorStyles.includes(cursorType) && cursorType) || false;
    dispatch({ type: 'CURSOR_TYPE', cursorType: cursorType });
  };

  return (
    <ThemeProvider theme={currentTheme === 'light' ? lightTheme : darkTheme}>
      <GlobalStyle />
      <CustomCursor toggleMenu={toggleMenu} linkSection={linkSection} />
      <Header
        refs={logoRef}
        onCursor={onCursor}
        setToggleMenu={setToggleMenu}
        toggleMenu={toggleMenu}
        hamburgerPosition={hamburger}
        setHamburgerPosition={setHamburgerPosition}
        moveHeaderUp={moveHeaderUp}
      />
      <Navigation
        onCursor={onCursor}
        toggleMenu={toggleMenu}
        setToggleMenu={setToggleMenu}
      />
      <main>{children}</main>
      <Footer
        onCursor={onCursor}
        hamburgerPosition={hamburger}
        setHamburgerPosition={setHamburgerPosition}
      />
      {/* <div className="copyrightStatement">© 2021 Ayusha Opus Metal Art & Design Inc. All rights reserved</div> */}
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
