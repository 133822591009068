import React, { useEffect, useState } from 'react';
import { Cursor } from '../styles/globalStyle';
//Context
import { useGlobalStateContext } from '../context/globalContext';

const CustomCursor = ({ toggleMenu, linkSection }) => {
  const { cursorType } = useGlobalStateContext();
  const [mousePosition, setMousePosition] = useState({
    x: -20,
    y: -20,
  });

  const onMouseMove = (e) => {
    const { pageX: x, pageY: y } = e;
    setMousePosition({ x, y });
  };

  useEffect(() => {
    document.addEventListener('mousemove', onMouseMove);
    return () => {
      document.removeEventListener('mousemove', onMouseMove);
    };
  }, []);
  return (
    <>
      <Cursor
        className={`${!!cursorType ? 'hovered' : ''}  ${cursorType} ${
          toggleMenu ? 'nav-open' : ''
        }
            ${linkSection ? 'nav-open' : ''}
            `}
        style={{ left: `${mousePosition.x}px`, top: `${mousePosition.y}px` }}
        // style={{ left: `${touchPosition.x}px`, top: `${touchPosition.y}px` }}
      />
    </>
  );
};

export default CustomCursor;
