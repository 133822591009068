// eslint-disable prettier/prettier
import React, { useEffect, useRef } from 'react';
import {
  Banner,
  BannerTitle,
  Canvas,
  Headline,
  SubHeadline,
  Video,
} from '../../styles/homeStyles';
import { ReactComponent as AO_Name_On_Black } from '../../assets/svg/AO_Name_on_black_bg.svg';
import { ReactComponent as AO_Name_On_White } from '../../assets/svg/AO_Name_on_white_bg.svg';
import { Move } from '../../assets/svg/social-icons';
import { Drag, DragMe, EctraSpace } from '../../styles/globalStyle';


// import trailerVideo from '../../assets/video/teaser.mp4';
// import mobile_teaser_under_drag from '../../assets/video/mobile/mobile_teaser_under_drag.mp4';


//Context
import { useGlobalStateContext } from '../../context/globalContext';

//Custom Hook
import useWindowSize from '../../hooks/useWindowSize';

const HomeBanner = ({ onCursor }) => {
  let canvas = useRef(null);
  let drag = useRef(null);
  const size = useWindowSize();
  const { currentTheme } = useGlobalStateContext();
  useEffect(() => {
    let renderingElement = canvas.current;
    let dragElement = drag.current;
    // if (size.width > '800px') {
    //   dragElement.style.display = 'none';
    //   console.log('> than 800px');
    // }
    let drawingElement = renderingElement.cloneNode();

    let drawingContext = drawingElement.getContext('2d');
    let renderingContext = renderingElement.getContext('2d');

    let lastX;
    let lastY;
    let moving = false;
    renderingContext.globalCompositeOperation = 'source-over';
    renderingContext.fillStyle = `${currentTheme === 'dark' ? '#000' : '#fff'}`;
    renderingContext.fillRect(0, 0, size.width, size.height);

    // mouse actions

    renderingElement.addEventListener('mouseover', (e) => {
      moving = true;
      lastX = e.pageX - renderingElement.offsetLeft;
      lastY = e.pageY - renderingElement.offsetTop;
    });
    renderingElement.addEventListener('mouseup', (e) => {
      moving = false;
      lastX = e.pageX - renderingElement.offsetLeft;
      lastY = e.pageY - renderingElement.offsetTop;
    });
    renderingElement.addEventListener('mousemove', (e) => {
      if (moving) {
        drawingContext.globalCompositeOperation = 'source-over';
        renderingContext.globalCompositeOperation = 'destination-out';
        let currentX = e.pageX - renderingElement.offsetLeft;
        let currentY = e.pageY - renderingElement.offsetTop;

        drawingContext.lineJoin = 'round';
        drawingContext.moveTo(lastX, lastY);
        drawingContext.lineTo(currentX, currentY);
        drawingContext.closePath();
        drawingContext.lineWidth = 120;
        drawingContext.stroke();
        lastX = currentX;
        lastY = currentY;
        renderingContext.drawImage(drawingElement, 0, 0);
      }
    });

    // // touch actions

    let lastTouchX;
    let lastTouchY;

    dragElement.addEventListener('touchstart', (e) => {
      moving = true;
      lastTouchX = e.changedTouches[0].pageX - renderingElement.offsetLeft;
      lastTouchY = e.changedTouches[0].pageY - renderingElement.offsetTop;
    });
    dragElement.addEventListener('touchend', (e) => {
      moving = false;
      lastTouchX = e.changedTouches[0].pageX - renderingElement.offsetLeft;
      lastTouchY = e.changedTouches[0].pageY - renderingElement.offsetTop;
    });

    dragElement.addEventListener('touchmove', (e) => {
      e.preventDefault();
      var touches = e.changedTouches;

      function ongoingTouchIndexById(idToFind) {
        for (let i = 0; i < touches.length; i++) {
          const id = touches[i].identifier;

          if (id === idToFind) {
            return i;
          }
        }
        return -1; // not found
      }

      for (var i = 0; i < touches.length; i++) {
        var idx = ongoingTouchIndexById(touches[i].identifier);

        if (moving && idx >= 0) {
          drawingContext.globalCompositeOperation = 'source-over';
          renderingContext.globalCompositeOperation = 'destination-out';
          let currentX = touches[idx].pageX - renderingElement.offsetLeft;
          let currentY = touches[idx].pageY - renderingElement.offsetTop;

          drawingContext.lineJoin = 'round';
          drawingContext.moveTo(lastTouchX, lastTouchY);
          drawingContext.lineTo(currentX, currentY);
          drawingContext.closePath();
          drawingContext.lineWidth = 100;
          drawingContext.stroke();
          lastTouchX = currentX;
          lastTouchY = currentY;
          dragElement.style.transform = `translate(${currentX}px, ${currentY}px)`;
          renderingContext.drawImage(drawingElement, 0, 0);
        }
      }
    });
  }, [currentTheme, size]);

  // console.log(size.width);

  const child = {
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
      transition: { duration: 1, ease: [0.6, 0.05, -0.01, 0.9] },
    },
  };

  function is_touch_enabled() {
    return (
      'ontouchstart' in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    );
  }

  return (
    <Banner>
      <Video>
        <video
          height={'100%'}
          width={'100%'}
          loop
          autoPlay
          muted
          playsinline webkit-playsinline webkit-playsinline="true" playsinline="true"
          // src={size.width < 500 ? mobile_teaser_under_drag : trailerVideo}
          src={size.width < 500 ? 'https://ayushaopus.eu-central-1.linodeobjects.com/541279.88feae3f.mp4'
                                : 'https://ayushaopus.eu-central-1.linodeobjects.com/762109.6c9de42f.mp4'
                              }
          // src={size.width < 500 ? 'https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/mobile_teaser_under_drag.mp4'
          //                       : 'https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/teaser.mp4'
          //                     }
        />
      </Video>

      <Drag ref={drag} className={`${!is_touch_enabled() ? 'noDrag' : ''}`}>
        {/* <Drag ref={drag} className={`${size.width > 480 ? 'noDrag' : ''}`}> */}
        {/* <Drag ref={drag}> */}
        {/* <DragMe> Drag </DragMe> */}
        <Move />
      </Drag>
      <Canvas
        height={size.height}
        width={size.width}
        ref={canvas}
        onMouseEnter={() => onCursor('hovered')}
        onMouseLeave={onCursor}
      />
      {/* {showDrag} */}

      <BannerTitle>
        {/* <Headline variants={child}>AYUSHA OPUS</Headline> */}
        {/* <SubHeadline variants={child}>metal art & design</SubHeadline> */}
        {currentTheme === 'dark' ? <AO_Name_On_Black /> : <AO_Name_On_White />}
      </BannerTitle>
    </Banner>
  );
};

export default HomeBanner;
