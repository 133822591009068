import React from 'react';
import Jumbotron from '../../Components/jumbotron/index';
import useWindowSize from '../../hooks/useWindowSize';
// import artist1 from '../../assets/img/artist/artist1.jpg';
// import artist2 from '../../assets/img/artist/artist2.jpg';
// import artist3 from '../../assets/img/artist/artist3.jpg';
// import artist4 from '../../assets/img/artist/artist4.jpg';

export function JumbotronContainer() {
  const size = useWindowSize();
  const artist1 =
    'https://ayushaopus.eu-central-1.linodeobjects.com/814789.02f444ed.jpg';
  const artist2 =
    'https://ayushaopus.eu-central-1.linodeobjects.com/327680.30d7c75a.jpg';
  const artist3 =
    'https://ayushaopus.eu-central-1.linodeobjects.com/967527.b2494141.jpg';
  const artist4 =
    'https://ayushaopus.eu-central-1.linodeobjects.com/290252.8dec764b.jpg';

  return (
    <>
      <div className='Desktop-Container'>
        {/* Jumbotron 1 */}
        <div className='Jumbo'>
          <Jumbotron.Container className='artist1'>
            <Jumbotron direction='row'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    ابن، أب، صديق، مدرس، أخصائي قلب إكلينيكي، صاحب مطعم، معماري،
                    صانع، فنان وصاحب رؤية.
                  </p>
                  <p>
                    الحياة اسم يطلق على بعض الأوسمة والإنجازات طوال هذه الرحلة.
                    محترفاً مرخصاً في مجال طب القلب والتعليم. لطالما كان Nilesh
                    Bhathella فنياً بطبيعته وشغوفاً بالتصميم، لكن هذا المكان
                    ووسيلة التعبير لم يتحققان إلا في وقت لاحق من الحياة؛ التي
                    أصبحت تؤتي ثمارها الآن. كيف ينعطف مسار الحياة من علم ملموس
                    إلى مثل هذا العالم الفني الإبداعي؟
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  src={artist1}
                  // src ='https://ayushaopus.eu-central-1.linodeobjects.com/814789.02f444ed.jpg'
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/artist1.jpg'
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>
          {/* Jumbotron 2 */}

          <Jumbotron.Container className='artist2'>
            <Jumbotron direction='row-reverse'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    يفترض Nilesh أن العلم في حد ذاته فن يتحدث من خلال اهتزاز كل
                    نبضة قلب، مبتكراً الشكل الفني النهائي الذي نحن عليه.
                  </p>
                  <p>
                    من خلال التفاعل والتواصل مع آلاف المرضى على مر السنين، أُتيح
                    لــــ Nilesh فرصةً ذهبية ليرى من وجهات نظر متعددة، ويروي
                    بكلمات مقتضبة أن أعظم إنجاز في هذه الحياة هو امتلاك القدرة
                    على انشاء العالم من حولك بحيث يتناغم مع اهتزازات نبضات قلبك
                    وأحلامك؛ شكل من أشكال التخيل الإبداعي لتحسين محيطك والواقع
                    المادي الملموس بطرق عميقة ذات مغزى.
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  src={artist2}
                  // src = 'https://ayushaopus.eu-central-1.linodeobjects.com/327680.30d7c75a.jpg'
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/artist2.jpg'
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>
          {/* Jumbotron 3 */}

          <Jumbotron.Container className='artist3'>
            <Jumbotron direction='row'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    الابداع، الاهتزاز، والطاقة والروح البشرية كلها يتم تجميعها
                    كما قام Nilesh بجمع فريد للغاية للمهارات المعقدة والرؤية
                    الفنية، يتردد صداه طوال هذه الرحلة الفنية الابداعية. يتقاطع
                    كل من الفن والعلم هنا لخلق وابتكار جمال خلاب مطلق للجميع
                    للاستمتاع به.
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/artist3.jpg'
                  // src  = 'https://ayushaopus.eu-central-1.linodeobjects.com/967527.b2494141.jpg'
                  src={artist3}
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>

          {/* Jumbotron 4 */}

          <Jumbotron.Container className='artist4'>
            <Jumbotron direction='row-reverse'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    دع سمو وعظمة هذا الفن تهز عصب الحياة في أعمق جزء من روحك؛
                    محولاً طاقتك إلى ابداع فني بهي.
                  </p>
                  <p className={size.width < 1000 ? 'displayNone' : ''}>
                    يدرك الوعي، ثم يحكم، ويصبح في النهاية عالمك المادي الملموس.
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/artist4.jpg'
                  // src = 'https://ayushaopus.eu-central-1.linodeobjects.com/290252.8dec764b.jpg'
                  src={artist4}
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
              {size.width < 1000 ? (
                <Jumbotron.Pane>
                  <Jumbotron.Text>
                    <p>
                      يدرك الوعي، ثم يحكم، ويصبح في النهاية عالمك المادي
                      الملموس.
                    </p>
                  </Jumbotron.Text>
                </Jumbotron.Pane>
              ) : null}
            </Jumbotron>
          </Jumbotron.Container>
        </div>
      </div>

      <div className='Mobile-Container'>
        <div className='Jumbo'>
          {/* Jumbotron 1 */}
          <div className='artist1'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    ابن، أب، صديق، مدرس، أخصائي قلب إكلينيكي، صاحب مطعم، معماري،
                    صانع، فنان وصاحب رؤية.
                  </p>
                  <p>
                    الحياة اسم يطلق على بعض الأوسمة والإنجازات طوال هذه الرحلة.
                    محترفاً مرخصاً في مجال طب القلب والتعليم. لطالما كان Nilesh
                    Bhathella فنياً بطبيعته وشغوفاً بالتصميم، لكن هذا المكان
                    ووسيلة التعبير لم يتحققان إلا في وقت لاحق من الحياة؛ التي
                    أصبحت تؤتي ثمارها الآن. كيف ينعطف مسار الحياة من علم ملموس
                    إلى مثل هذا العالم الفني الإبداعي؟
                  </p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Image
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/artist1.jpg'
                  // src = 'https://ayushaopus.eu-central-1.linodeobjects.com/814789.02f444ed.jpg'
                  src={artist1}
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </div>
            </div>
          </div>

          {/* Jumbotron 2 */}

          <div className='artist2'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    يفترض Nilesh أن العلم في حد ذاته فن يتحدث من خلال اهتزاز كل
                    نبضة قلب، مبتكراً الشكل الفني النهائي الذي نحن عليه.
                  </p>
                  <p>
                    من خلال التفاعل والتواصل مع آلاف المرضى على مر السنين، أُتيح
                    لــــ Nilesh فرصةً ذهبية ليرى من وجهات نظر متعددة، ويروي
                    بكلمات مقتضبة أن أعظم إنجاز في هذه الحياة هو امتلاك القدرة
                    على انشاء العالم من حولك بحيث يتناغم مع اهتزازات نبضات قلبك
                    وأحلامك؛ شكل من أشكال التخيل الإبداعي لتحسين محيطك والواقع
                    المادي الملموس بطرق عميقة ذات مغزى.
                  </p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Image
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/artist2.jpg'
                  // src = 'https://ayushaopus.eu-central-1.linodeobjects.com/327680.30d7c75a.jpg'
                  src={artist2}
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </div>
            </div>
          </div>

          {/* Jumbotron 3 */}

          <div className='artist3'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    الابداع، الاهتزاز، والطاقة والروح البشرية كلها يتم تجميعها
                    كما قام Nilesh بجمع فريد للغاية للمهارات المعقدة والرؤية
                    الفنية، يتردد صداه طوال هذه الرحلة الفنية الابداعية. يتقاطع
                    كل من الفن والعلم هنا لخلق وابتكار جمال خلاب مطلق للجميع
                    للاستمتاع به.
                  </p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Image
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/artist3.jpg'
                  // src = 'https://ayushaopus.eu-central-1.linodeobjects.com/967527.b2494141.jpg'
                  src={artist3}
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </div>
            </div>
          </div>

          {/* Jumbotron 4 */}

          <div className='artist4'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    دع سمو وعظمة هذا الفن تهز عصب الحياة في أعمق جزء من روحك؛
                    محولاً طاقتك إلى ابداع فني بهي.
                  </p>
                  <p className={size.width < 1000 ? 'displayNone' : ''}>
                    يدرك الوعي، ثم يحكم، ويصبح في النهاية عالمك المادي الملموس.
                  </p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Image
                  src={artist4}
                  // src = 'https://ayushaopus.eu-central-1.linodeobjects.com/290252.8dec764b.jpg'
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/artist4.jpg'
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </div>
              {size.width < 1000 ? (
                <div>
                  <Jumbotron.Text>
                    <p>
                      يدرك الوعي، ثم يحكم، ويصبح في النهاية عالمك المادي
                      الملموس.
                    </p>
                  </Jumbotron.Text>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
