import React, { useState } from 'react';
import { Container, Flex } from '../styles/globalStyle';
import { AnimatePresence, motion } from 'framer-motion';
import { useGlobalStateContext } from '../context/globalContext';
import {
  CloseNav,
  Nav,
  NavFooter,
  NavHeader,
  NavList,
  NavVideos,
} from '../styles/styleNavigation';
import { Link } from 'react-router-dom';

const Navigation = ({ setToggleMenu, toggleMenu, onCursor }) => {
  const { currentTheme } = useGlobalStateContext();
  const [revealVideo, setRevealVideo] = useState({
    show: false,
    // video: 'video.mp4',
    video:
      'https://ayushaopus.eu-central-1.linodeobjects.com/814789.02f444ed.jpg',
    key: 0,
    className: '',
  });

  const navRouts = [
    {
      id: 0,
      title: 'الفنان',
      path: '/artist',
      className: 'artist',
      video:
        'https://ayushaopus.eu-central-1.linodeobjects.com/814789.02f444ed.jpg',
    },
    {
      id: 1,
      title: 'الدلالة والمعنى',
      path: '/meaning',
      className: 'meaning',
      video: `${
        currentTheme === 'dark'
          ? 'https://ayushaopus.eu-central-1.linodeobjects.com/209167.d5800390.png'
          : 'https://ayushaopus.eu-central-1.linodeobjects.com/571096.2a5ee135.png'
      }`,
    },
    {
      id: 2,
      title: 'الفلسفة',
      path: '/philosophy',
      className: 'philosophy',
      video:
        'https://ayushaopus.eu-central-1.linodeobjects.com/171406.245c981e.jpg',
    },
    {
      id: 3,
      title: 'الرؤية & العمل الخيري',
      path: '/vision-philanthropy',
      className: 'vision',
      video:
        'https://ayushaopus.eu-central-1.linodeobjects.com/915689.afcf01867.jpg',
    },
    {
      id: 4,
      title: 'الجمال',
      path: '/beauty',
      className: 'beauty',
      video:
        'https://ayushaopus.eu-central-1.linodeobjects.com/914660.f05bef817.jpg',
    },
    {
      id: 5,
      title: 'الفن المعدني',
      path: '/metal',
      className: 'metal',
      video:
        'https://ayushaopus.eu-central-1.linodeobjects.com/284170.58016c361.jpg',
    },
    // {id: 6, title: "Application", path: "/applications", video: "video.mp4"},
    {
      id: 7,
      title: 'الالتزام ',
      path: '/commitment',
      className: 'commitment',
      video:
        'https://ayushaopus.eu-central-1.linodeobjects.com/5643218.f88b6e0c.jpg',
    },
    {
      id: 8,
      title: 'التواصل ',
      path: '/contact',
      className: 'contact',
      // video: 'https://ayushaopus.eu-central-1.linodeobjects.com/5643218.f88b6e0c.jpg',
      video: '/video/video5.mp4',
    },
  ];
  return (
    <>
      <AnimatePresence>
        {toggleMenu && (
          <Nav
            initial={{ x: '-100%' }}
            exit={{ x: '-100%' }}
            animate={{ x: toggleMenu ? 0 : '-100%' }}
            transition={{ duration: 0.8, ease: [0.6, 0.5, -0.01, 0.9] }}
          >
            <Container>
              <NavHeader>
                <Flex spaceBetween noHeight>
                  <h2></h2>
                  <CloseNav
                    onClick={() => setToggleMenu(!toggleMenu)}
                    onMouseEnter={() => onCursor('pointer')}
                    onMouseLeave={() => onCursor()}
                  >
                    <button>
                      <span className='line1' />
                      <span className='line2' />
                    </button>
                  </CloseNav>
                </Flex>
              </NavHeader>
              <NavList>
                <ul>
                  {navRouts.map((route) => (
                    <motion.li
                      className={route.className}
                      onMouseEnter={() => onCursor('pointer')}
                      onMouseLeave={() => onCursor()}
                      onHoverStart={() =>
                        setRevealVideo({
                          show: true,
                          video: route.video,
                          key: route.id,
                          className: route.className,
                        })
                      }
                      onHoverEnd={() =>
                        setRevealVideo({
                          show: false,
                          video: route.video,
                          key: route.id,
                        })
                      }
                      key={route.id}
                    >
                      <Link to={`${route.path}`}>
                        <motion.div
                          initial={{ x: -108 }}
                          whileHover={{ x: -40 }}
                          transition={{
                            duration: 0.4,
                            ease: [0.6, 0.05, -0.01, 0.9],
                          }}
                          className='link'
                        >
                          <span className='arrow'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              viewBox='0 0 101 57'
                            >
                              <path
                                d='M33 34H0V24h81.429L66 7.884 73.548 0l19.877 20.763.027-.029L101 28.618 73.829 57l-7.548-7.884L80.753 34H33z'
                                fill='#FFF'
                                fillRule='evenodd'
                              />
                            </svg>
                          </span>
                          {route.title}
                        </motion.div>
                      </Link>
                    </motion.li>
                  ))}
                </ul>
              </NavList>
              <NavVideos>
                {/* <motion.div
                  animate={{ width: revealVideo.show ? 0 : '100%' }}
                  className='reveal'
                /> */}
                <motion.div
                  // animate={{ width: revealVideo.show ? '100%' : 0 }}
                  animate={{
                    transform: revealVideo.show
                      ? 'translateX(0%)'
                      : 'translateX(100%)',
                  }}
                  className={revealVideo.key === 8 ? 'video' : 'img'}
                >
                  <AnimatePresence initial={false} exitBeforeEnter>
                    {revealVideo.key === 8 ? (
                      <motion.video
                        key={revealVideo.key}
                        initial={{ opacity: 0 }}
                        exit={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        className={revealVideo.className}
                        transition={{ duration: 0.1, ease: 'easeInOut' }}
                        src={
                          'https://ayushaopus.eu-central-1.linodeobjects.com/850139.6997488d.mp4'
                        }
                        muted
                        loop
                        playsinline
                        webkit-playsinline
                        webkit-playsinline='true'
                        playsinline='true'
                        autoPlay={true}
                      />
                    ) : (
                      <motion.img
                        key={revealVideo.key}
                        initial={{ opacity: 0 }}
                        exit={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        className={revealVideo.className}
                        transition={{ duration: 0.1, ease: 'easeInOut' }}
                        src={
                          // require(`../assets${revealVideo.video}`).default
                          revealVideo.video
                        }
                      />
                    )}
                  </AnimatePresence>
                </motion.div>
              </NavVideos>
            </Container>
          </Nav>
        )}
      </AnimatePresence>
    </>
  );
};

export default Navigation;
