import React, { useEffect, useState } from 'react';
import {
  FeaturedContent,
  FeaturedVideo,
  HomeFeaturedSection,
} from '../../styles/Project/ProjectBanner';
import { Container, Flex } from '../../styles/globalStyle';
import { Link } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';

// import trailerVideo from '../../assets/video/teaser.mp4';

import { useInView } from 'react-intersection-observer';

const ProjectBanner = ({
  onCursor,
  toggleMenu,
  setToggleMenu,
  noVideo,
  noFeaturedVideo,
  title,
  page,
}) => {
  const [hovered, setHovered] = useState(false);
  const animation = useAnimation();
  const [featuredRef, inVew] = useInView({
    triggerOnce: true,
    rootMargin: '-300px',
  });

  useEffect(() => {
    if (inVew) {
      animation.start('visible');
    }
  }, [inVew, animation]);
  return (
    <HomeFeaturedSection
      ref={featuredRef}
      animate={animation}
      variants={{
        visible: {
          opacity: 1,
          y: 0,
          transition: { duration: 0.6, ease: [0.6, 0.05, -0.01, 0.9] },
        },
        hidden: { opacity: 0, y: 72 },
      }}
    >
      <Container>
        {/*<Link to={"/"}>*/}
        <FeaturedContent
          onHoverStart={() => setHovered(!hovered)}
          onHoverEnd={() => setHovered(!hovered)}
          onMouseEnter={() => onCursor('hovered')}
          onMouseLeave={() => onCursor()}
        >
          {!noFeaturedVideo && (
            <Flex spaceBetween>
              <h3>Featured Project</h3>
            </Flex>
          )}

          {title && page === 'vision' ? (
            <h2 className='featured-title vision page-title vision-title'>{title}</h2>
          ) : (title && page === "Commitment") ? (
            <h2 className='featured-title page-title commitment-page-title vision-title'>{title}</h2>
          ) : title ? (
            <h2 className='featured-title page-title'>{title}</h2>
          ) : (
            <h2 className='featured-title'>
              NOT <br /> HUMBLE
            </h2>
          )}
        </FeaturedContent>
        {!noFeaturedVideo && (
          <FeaturedVideo>
            <video 
              loop 
              playsinline 
              webkit-playsinline 
              webkit-playsinline="true" 
              playsinline="true" 
              autoPlay muted 
              // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/teaser.mp4' 
              src = 'https://ayushaopus.eu-central-1.linodeobjects.com/762109.6c9de42f.mp4'
              // src={trailerVideo} 
            />
          </FeaturedVideo>
        )}
        {/*</Link>*/}
      </Container>
    </HomeFeaturedSection>
  );
};

export default ProjectBanner;
