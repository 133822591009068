import React, { useEffect } from 'react';
import {
  CopyrightHeader,
  CopyrightMain,
  CopyrightPara,
  CopyrightSubHeading,
  CopyrightStatement,
  CopyrightLogo,
} from '../styles/copyright';

//Styled Component
import { createGlobalStyle, ThemeProvider } from 'styled-components/macro';
import { normalize } from 'styled-normalize';

import CustomCursor from '../Components/CustomCursor';
import { CopyrightHomeAccordian } from '../Components/Homepage/HomeAbout';

import {
  useGlobalStateContext,
  useGlobalDispatchContext,
} from '../context/globalContext';

import { ReactComponent as PoloYSVG } from '../assets/svg/AO_Logo_Isotype_RGB-01.svg';
import { analytics } from '../firebase/index';

const GlobalStyle = createGlobalStyle`
  ${normalize}
  * {
    text-decoration: none;
    cursor: none;
  }

  html {
    box-sizing: border-box;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
  }

  body {
    font-family: 'Lato', sans-serif;
    background: ${(props) => props.theme.background};
    overscroll-behavior: none;
    overflow-x: hidden;
  }

  /*  @font-face {
      font-family: 'Caviar Dreams';
      font-style: normal;
      font-weight: 300;
      src: local('Caviar Dreams'), url('../assets/fonts/CaviarDreams.tff') format('woff');
    }*/


  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #6d6d6d;
  }

  ::-webkit-scrollbar {
    width: 2px;
    @media (max-width: 768px) {
      width: 0px;
      display: none;
      }
  }

  ::-webkit-scrollbar-track {
    background-color: #4e555b;
  }
`;

export default function Copyright({ setLinkSection, onCursor }) {
  useEffect(() => {
    analytics.logEvent('Copyright_Page_visited');
  }, []);

  const darkTheme = {
    background: '#000',
    text: '#fff',
    red: '#f42835',
    // red: 'linear-gradient(to right,#9c192f,5%,#f42835,20%,#aa0034,80%,#eb002a,95%,#b1212b)',
  };
  const lightTheme = {
    text: '#000',
    background: '#fff',
    red: '#f42835',
  };
  const { currentTheme, cursorStyles } = useGlobalStateContext();
  const dispatch = useGlobalDispatchContext();

  return (
    <ThemeProvider theme={currentTheme === 'light' ? lightTheme : darkTheme}>
      <GlobalStyle />
      <CustomCursor />
      <CopyrightMain>
        {/* <CopyrightHeader>
                Ayusha Metal Art & Design Inc. <br /> Terms of Use Agreement, disclaimers and caveats.
                </CopyrightHeader> */}
        <CopyrightLogo>
          <PoloYSVG
            className={'poloySVG'}
            onMouseEnter={() => onCursor('pointer')}
            onMouseLeave={onCursor}
          />
        </CopyrightLogo>

        <CopyrightHeader>
          Ayusha Opus <br /> Metal Art & Design Inc.
        </CopyrightHeader>

        <CopyrightStatement>اتفاقية شروط الاستخدام</CopyrightStatement>

        <CopyrightPara>
          من خلال استخدام هذا الموقع، فإنك تقر أنك قمت بقراءة اتفاقية شروط
          الاستخدام واخلاء المسؤولية والتحفظات الواردة في هذا الموقع، وعلى هذا
          فإنك توافق وسوف تلتزم بشروطها..
        </CopyrightPara>
        <CopyrightSubHeading>على الصعيد العام</CopyrightSubHeading>
        <CopyrightPara>
          يرجى قراءة جميع المعلومات الواردة في هذه الصفحة بعناية قبل استخدام
          موقع Ayusha Opus Metal Art & Design Inc. يتم إدارة الموقع من قبل شركة
          Ayusha Metal Art & Design Inc. (منشأة اتحادية مسجلة تجارياً) وNilesh
          Bhathella.
        </CopyrightPara>
        <CopyrightPara>
          محتوى هذا الموقع هو ملكية فكرية لشركة Ayusha Opus Metal Art & Design
          Inc. Nilesh Bhathella، فلا يجوز لك إعادة استخدامه، إعادة نشره، أو
          إعادة طباعة بعض المحتوى دون موافقة خطية صريحة رسمية.
        </CopyrightPara>
        <CopyrightPara>
          جميع المعلومات المنشورة لأهداف وأغراض تعليمية وإعلامية، ولا يقصد أن
          تحل محل أي نصيحة مهنية. فإن قررت التصرف وفقاً لأي معلومات على هذا
          الموقع، فأنت تفعل ذلك على مسؤوليتك الخاصة.
        </CopyrightPara>
        <CopyrightPara>
          في حين جرى التحقق من المعلومات المتواجدة على هذا الموقع بأفضل القدرات
          التي نمتلكها، لا يمكننا ضمان عدم وجود أخطاء.{' '}
        </CopyrightPara>
        <CopyrightPara>
          نحتفظ بالحق في تغيير هذه السياسة في أي وقت من الأوقات، ولن يتم اطلاعك
          عليها على الفور؛ مالم توافق على قراءة اخلاء المسؤولية هذه، حيث تعتبر
          من مسؤوليتك قبل استخدام هذا الموقع في كل مرة ليتم تحديثه بأية تغييرات
          مترتبة والتي ربما تهمك أو لا تعنيك.
        </CopyrightPara>
        <CopyrightPara>
          إن كنت تود التأكد من أنك تواكب آخر التغييرات، فإننا نوصيك بشدة زيارة
          هذه الصفحة باستمرار.
        </CopyrightPara>
        <CopyrightPara>
          لا تمنح شركة Ayusha Opus Metal Art & Design Inc وNilesh Bhathella أية
          ضمانات ولا تتحمل أية مسؤوليات أو التزامات عن دقة واكتمال المعلومات
          والمواد الواردة في هذا الموقع، ولن تتحمل شركة Ayusha Opus Metal Art &
          Design Inc وNilesh Bhathella تحت أي ظرف من الظروف أي مسؤولية أو التزام
          بأي شكل من الأشكال عن أية مطالبات، أضرار، خسائر، نفقات، تكاليف أو
          التزامات من أي نوع (بما في ذلك، على سبيل المثال لا الحصر، أي أضرار
          مباشرة أو غير مباشرة عن الخسائر والأرباح أو انقطاع الأعمال أو فقدان
          المعلومات) الناتجة أو الناشئة بشكل مباشر أو غير مباشر من استخدامك أو
          عدم قدرتك على استخدام هذا الموقع أو أي مواقع الكترونية أو مقاطع فيديو
          الجارية أو مواقع التواصل الاجتماعي المرتبطة به، أو عن اعتمادك على
          المعلومات ومواد هذا الموقع، حتى إن تم اعلام شركة Ayusha Opus Metal Art
          & Design Inc. و Nilesh Bhathella بإمكانية حدوث مثل هذه الأضرار مسبقاً.
        </CopyrightPara>
        <CopyrightPara>
          الآراء التي تم ابداءها في هذا الموقع أو خلاله هي من كتابة المؤلفين حسب
          صفاتهم الشخصية. تم التنازل عن كافة المسؤوليات المتعلقة بالإجراءات التي
          تم الأخذ بها أو لم يتم الاتخاذ بها بناءً على محتويات هذا الموقع
          صراحةً. يتم تزويد محتوى هذا الموقع "كما هو" ولا يتم تقديم أية إقرارات
          بأن المحتوى خالٍ من الأخطاء.
        </CopyrightPara>

        <CopyrightSubHeading id='copyright'>
          حقوق النشر والتأليف
        </CopyrightSubHeading>
        <CopyrightPara>
          Ayusha Opus Metal Art & Design Inc./ Nilesh Bhathella
        </CopyrightPara>
        <CopyrightPara>
          © 2021 Ayusha Opus Metal Art & Design Inc. جميع الحقوق محفوظة
        </CopyrightPara>
        <CopyrightPara>
          © Nilesh Bhathella 2021 جميع الحقوق محفوظة
        </CopyrightPara>
        <CopyrightPara>
          حقوق الفنان (Nilesh Bhathella وجميع الأعمال المرتبطة)، محمية بموجب
          قانون حقوق النشر والتأليف الكندي (R.S.C., 1985, c. C-42) القانون
          الاتحادي لحقوق النشر والتأليف لعام 1976 والقانون الاتحادي لحقوق
          الفنانين التشكيليين لعام 1990، لا يؤدي شراء حقوق النشر والتأليف للعمل
          الفني إلى نقل حقوق النشر والتأليف. حقوق النشر والتأليف لانتاج نسخ من
          الأعمال الفنية، وانتاج أعمال مشتقة بناءً على نسخ وطباعة الصورة، يحق
          للفنان وحده الاحتفاظ بنشر النسخ. يجب الموافقة رسمياً على أي نقل لحقوق
          النشر والتأليف بالكتابة الصريحة مع تحديد ماهية الحقوق التي يتم بيعها
          ولأي الأغراض؛ وبخلاف ذلك، يتم الاحتفاظ صراحةً وبشكل منفرد بكافة حقوق
          النشر والتأليف والأعمال الفكرية للفنون من قبل الفنان (Nilesh
          Bhathella) وشركة Ayusha Opus Metal Art & Design Inc.
        </CopyrightPara>
        <CopyrightPara>
          لا يمكن تعديل الأعمال الفنية دون أخذ إذن الفنان. لا يجوز توزيع الأعمال
          الفنية، تشويهها أو تعديلها بأي طريقة من شأنها الاضرار بسمعة الفنان.
        </CopyrightPara>
        <CopyrightPara>
          يعد الفيديو الرئيسي والفيديو الاعلاني القصير من حقوق النشر والتأليف
          الكاملة لدى شركة Ayusha Opus Metal Art & Design Inc. وNilesh
          Bhathella. لا يجوز إعادة إنتاج أي جزء من مقاطع الفيديو على الاطلاق أو
          نسخها بأي شكل من الأشكال.
        </CopyrightPara>
        <CopyrightPara>
          جميع الأعمال الفنية المعروضة ضمن هذا الموقع الالكتروني محمية بحقوق
          النشر والتأليف الكاملة لدى شركة Ayusha Opus Metal Art & Design Inc.
          وNilesh Bhathella. لا يجوز إعادة إنتاج أي جزء على الاطلاق أو نسخها بأي
          شكل من الأشكال.
        </CopyrightPara>
        <CopyrightPara>
          جميع الصور الفوتوغرافية والصور المعروضة ضمن هذا الموقع الالكتروني
          محمية بحقوق النشر والتأليف الكاملة لدى شركة Ayusha Opus Metal Art &
          Design Inc. وNilesh Bhathella. لا يجوز إعادة إنتاج أي جزء على الاطلاق
          أو نسخها بأي شكل من الأشكال.
        </CopyrightPara>
        <CopyrightPara>
          جميع المحتويات المكتوبة ضمن هذا الموقع محمية بحقوق النشر والتأليف
          الكاملة لدى شركة Ayusha Opus Metal Art & Design Inc. وNilesh
          Bhathella. لا يجوز إعادة إنتاج أي جزء على الاطلاق أو نسخها بأي شكل من
          الأشكال.
        </CopyrightPara>

        <CopyrightSubHeading>إشعار حقوق النشر والتأليف</CopyrightSubHeading>
        <CopyrightPara>
          1.1 حقوق النشر والتأليف © Ayusha Opus Metal Art & Design Inc.
        </CopyrightPara>
        <CopyrightPara>
          1.2 حقوق النشر والتأليف © Nilesh Bhathella
        </CopyrightPara>
        <CopyrightPara>
          1.3 مع مراعاة الأحكام الصريحة لهذا الإشعار:
        </CopyrightPara>
        <CopyrightPara>
          (a) نحن، إضافةً إلى مرخصينا، نمتلك ونتحكم بجميع حقوق التأليف والنشر
          وحقوق الملكية الفكرية الأخرى في هذا الموقع وكذلك المواد المتواجدة على
          هذا الموقع؛ و
        </CopyrightPara>
        <CopyrightPara>
          (b) جميع حقوق النشر والتأليف وحقوق الملكية الفكرية الأخرى في هذا
          الموقع وأيضا المواد المتواجدة على هذا الموقع محفوظة. ويشمل ذلك على
          سبيل المثال لا الحصر، الأعمال المنشورة على مواقع التواصل الاجتماعي
          مثل: الفيس بوك، تويتر، انستجرام وكافة وسائل التواصل الاجتماعي الأخرى
          بموجب شركة Ayusha Opus Metal Art & Design Inc. وشعار الشركة AO.
        </CopyrightPara>

        <CopyrightSubHeading>ترخيص حقوق النشر والتأليف</CopyrightSubHeading>
        <CopyrightPara>2.1 يجوز لك:</CopyrightPara>
        <CopyrightPara>(a) عرض صفحات من هذا الموقع في متصفح ويب؛</CopyrightPara>
        <CopyrightPara>
          (b) بث ملفات صوت وفيديو من الموقع الخاص بنا؛
        </CopyrightPara>
        <CopyrightPara>
          (c) تحميل صفحات من الموقع الخاص بنا لغرض وحيد وهو التخزين المؤقت
          للصفحات التي تم تنزيلها في إطار متصفح موقع الويب؛{' '}
        </CopyrightPara>
        <CopyrightPara>2.2 لا يجوز لك:</CopyrightPara>
        <CopyrightPara>
          طباعة و/أو نسخ أي صفحة من الصفحات أو بند من البنود، الصور أو مقاطع
          الفيديو من الموقع الالكتروني هذا؛ مع مراعاة الأحكام الأخرى لهذا
          الإشعار.{' '}
        </CopyrightPara>
        <CopyrightPara>
          2.3 باستثناء ما هو مسموح به صراحةً بموجب الأحكام الأخرى لهذا الاشعار،
          لا ينبغي عليك تنزيل أي مادة من هذا الموقع الالكتروني أو حفظ أي مادة من
          هذا القبيل بجهاز الكمبيوتر الخاص بك أو أي أجهزة حفظ أخرى.
        </CopyrightPara>
        <CopyrightPara>
          2.4. يجوز لك استخدام هذا الموقع الالكتروني لأغراضك الشخصية فقط، ولا
          ينبغي عليك استخدامه لأية أغراض أخرى.
        </CopyrightPara>
        <CopyrightPara>
          2.5 باستثناء ما هو مسموح به صراحةً بموجب هذا الاشعار، ينبغي عليك ألا
          تقوم بتحرير أو تعديل خلاف ذلك أي مادة على الموقع الالكتروني هذا.
        </CopyrightPara>
        <CopyrightPara>
          2.6 مالم تمتلك أو تتحكم بالحقوق المتعلقة بالمادة، يجب عليك ألا تقوم
          بما يلي:
        </CopyrightPara>
        <CopyrightPara>
          (a) إعادة نشر المواد المتواجدة على هذا الموقع (بما في ذلك إعادة النشر
          على مواقع أخرى)؛
        </CopyrightPara>
        <CopyrightPara>
          (b) بيع، تأجير، أو ترخيص المواد من هذا الموقع الالكتروني؛
        </CopyrightPara>
        <CopyrightPara>
          (c) عرض أي مادة من الموقع الالكتروني هذا على العامة؛
        </CopyrightPara>
        <CopyrightPara>
          (d) استغلال المواد من هذا الموقع لأغراض تجارية؛ تحقيق مكاسب مالية، أو؛
        </CopyrightPara>
        <CopyrightPara>
          (e) إعادة توزيع المواد من هذا الموقع، باستثناء الحد المسموح به صراحةً
          بموجب هذا الإشعار.
        </CopyrightPara>

        <CopyrightSubHeading>الاستخدام المقبول</CopyrightSubHeading>
        <CopyrightPara>3.1 ينبغي عليك عدم القيام بما يلي:</CopyrightPara>
        <CopyrightPara>
          (a) استخدام الموقع الخاص بنا بأي شكل من الأشكال أو اتخاذ أي إجراء
          يتسبب أو قد يتسبب بإلحاق الضرر في هذا الموقع، إضعاف أداؤه، اتاحته أو
          إمكانية الوصول إليه؛{' '}
        </CopyrightPara>
        <CopyrightPara>
          (b) استخدام هذا الموقع بأي طريقة غير شرعية، غير قانونية، احتيالية أو
          تلحق أضراراً، أو فيما يتعلق بأي غرض أو نشاط غير قانوني، احتيالي أو
          ضار.
        </CopyrightPara>
        <CopyrightPara>
          (c) استخدام هذا الموقع لنسخ، تخزين، استضافة، نقل، إرسال، استخدام، نشر،
          أو توزيع أي مادة تتكون من (أو ترتبط) بأي برامج تجسس، فيروسات أجهزة
          الحاسوب، حصان الطروادة، الفيروس، برامج تجسس للحاسوب، هجومات برمجية
          جذرية، أو أي برامج حاسوب ضارة.
        </CopyrightPara>
        <CopyrightPara>
          (d) إجراء أي أنشطة بصورة نظامية أو آلية لجمع البيانات (بما في ذلك على
          سبيل المثال لا الحصر، الكشط، التنقيب عن البيانات، استخراج البيانات،
          جمع البيانات) المتواجدة على هذا الموقع أو فيما يتعلق بهذا الموقع دون
          الحصول على موافقة كتابية رسمية صريحة من قبلنا.
        </CopyrightPara>

        <CopyrightSubHeading>الإبلاغ عن الاساءات</CopyrightSubHeading>
        <CopyrightPara>
          4.1. في حال علمت بأي مادة أو نشاط غير قانوني على هذا الموقع، أو أي
          مادة أو نشاط ينتهك هذا الاشعار، يرجى ابلاغنا بذلك.
        </CopyrightPara>
        <CopyrightPara>
          4.2. إن كنت لا تستطيع السماح بمعرفتنا (يمكنك مراسلتنا عبر البريد
          الالكتروني).
        </CopyrightPara>

        <CopyrightSubHeading>تنفيذ حقوق النشر والتأليف</CopyrightSubHeading>
        <CopyrightPara>
          5.1 نتخذ حماية حقوق التأليف والنشر الخاصة بنا على محمل الجد.
        </CopyrightPara>
        <CopyrightPara>
          5.2 في حال اكتشفنا أنك قمت باستخدام حقوق النشر والتأليف للمواد
          والأعمال الفنية الخاصة بنا بما يتعارض مع الترخيص المنصوص عليه في هذا
          البيان، يجوز لنا اتخاذ إجراءات قانونية تجاهك القوة الكاملة للقانون،
          مطالبين بتعويضات مالية و/أو أمر قضائي يمنعك من استخدام أي وجميع
          الأعمال الفنية والمواد الأخرى المحمية بحقوق التأليف والنشر. وسيطلب منك
          أيضاً دفع أي تكاليف قانونية تتعلق بالمخالفة على النحو المنصوص عليه
          أعلاه.
        </CopyrightPara>

        <CopyrightSubHeading>الصلاحيات والتراخيص</CopyrightSubHeading>
        <CopyrightPara>
          6.1 يمكنك طلب ترخيص استخدام المواد المحمية بحقوق النشر والتأليف
          المتواجدة على هذا الموقع من خلال ارسال بريداً الكترونياً إلينا
          باستخدام عناوين الاتصال المتاحة على هذا الموقع. سينتج عن الإخفاق في
          القيام بذلك إلى مخالفة كما هو منصوص عليه في إطار إنفاذ حقوق التأليف
          والنشر.
        </CopyrightPara>

        <CopyrightSubHeading>الاستثناءات الممنوحة</CopyrightSubHeading>
        <CopyrightPara>
          أصحاب حقوق النشر والتأليف والمؤلف صراحةً شركةAyusha Opus Metal Art &
          Design Inc. وNilesh Bhathella .
        </CopyrightPara>
        <CopyrightPara>
          تعود حقوق النشر والتأليف بصورة حصرية لمالك حقوق النشر والتأليف (يسمى
          أيضاً صاحب الحقوق). ينبغي الحصول على ترخيص من مالك حقوق النشر والتأليف
          لإعادة إنتاج أو عرض أو نقل أعمال، على سبيل المثال أي أعمال فنية أو
          مقاطع فيديو أو صور أو مقالات أو مراسلات خطية في هذا الموقع.{' '}
        </CopyrightPara>

        <CopyrightSubHeading>مصطلح حقوق التأليف والنشر</CopyrightSubHeading>
        <CopyrightPara>
          جميع الأعمال محمية بموجب قانون حقوق النشر والتأليف خلال فترة حياة
          المؤلف ولمدة 50 عاماً أخرى بعد نهاية السنة الميلادية من وفاة المؤلف.
          بعد انتهاء تلك الفترة، يصبح العمل الفني من الأملاك العامة ويمكن لأي
          شخص استخدامه دون ترخيص مالم تقوم شركة Ayusha Metal Art & Design Inc.
          بنقل ملكية الشركة الاتحادية قانونياً بعد وفاة المؤلف، وبالتالي تعتبر
          حقوق النشر والتأليف جزءاً من التي تنتمي لشركة Ayusha Metal Art &
          Design Inc. وشعار الشركة AO وينبغي أن يستمر مع جميع حقوق النشر
          والتأليف والعلامات التجارية على التوالي.{' '}
        </CopyrightPara>
        <CopyrightPara>
          الأعمال الفنية محمية وفقاً للمدة الزمنية المحددة في قانون حقوق المؤلف،
          امتثالاً لمبدأ المعاملة الوطني بموجب اتفاقية برن لحماية المصنفات
          الأدبية والفنية.
        </CopyrightPara>

        <CopyrightSubHeading>على الصعيد الدولي</CopyrightSubHeading>
        <CopyrightPara>
          بقيت كندا والولايات المتحدة الأمريكية عضواً في المنظمة العالمية
          للملكية الفكرية (ويبو) منذ عام 1970. كما وقعت مختلف المعاهدات الدولية
          للملكية الفكرية مثل معاهدة برن لحماية المصنفات الأدبية والفنية (1886)
          التي تحدد المبادئ التوجيهية لحماية حقوق النشر والتأليف الدولية.{' '}
        </CopyrightPara>

        <CopyrightSubHeading>
          الإقرار والاعتراف بحقوق النشر والتأليف
        </CopyrightSubHeading>
        <CopyrightPara>
          يحتوي الموقع الالكتروني هذا على مواد محمية بحقوق النشر والتأليف لم
          يصرح المالك استخدام حقوق النشر والتأليف في جميع الأحوال، نتيح مثل هذه
          المواد فيما نبذله من جهود من أجل تعزيز فهم الطبيعة الخيرية لأخلاقياتنا
          لرفع مستوى الوعي لهؤلاء الأقل حظاً في جميع أنحاء العالم ولأولئك الذين
          يعانون من الفقر وليس لديهم مصدر أساسي للغذاء والماء، وأي جهد تعاوني
          لأجل ذلك جدير بالتقدير صراحةً. ليس في نيتنا استخدام أي عمل محمي بحقوق
          النشر والتأليف لأغراض إعادة التوزيع أو الكسب المالي بأي شكل من
          الأشكال، وهذا يمثل "الاستخدام العادل" لأي من المواد المحمية بحقوق
          النشر والتأليف كما هو منصوص عليه في الباب107 من قانون حقوق النشر
          والتأليف في أمريكا.{' '}
        </CopyrightPara>
        <CopyrightPara>
          وفقاً للمادة 17 من قانون حقوق النشر والتأليف في أمريكا، الباب 107
          المادة (المحددة) الموجودة على هذا الموقع يتم توزيعها. في حال كنت ترغب
          باستخدام مواد محمية بحقوق النشر والتأليف من هذا الموقع لأغراض خاصة بك
          تتجاوز "الاستخدام العادل"، يجب أن تحصل أولاً على الترخيص من مالك حقوق
          النشر والتأليف.{' '}
        </CopyrightPara>
        <CopyrightPara>
          يرجى مراسلتنا عبر البريد الالكتروني بأي طلبات مثل هذه قبل القيام بذلك
          كما سوف يتم اعتباره انتهاكاً خلاف ذلك.
        </CopyrightPara>
        <CopyrightPara>
          بعض أسماء المنتجات والشركات المستخدمة هي علامات تجارية™ أو مسجلة®
          علامات تجارية مسجلة لمالكيها. بالتالي لا يعني استخدامها أي انتماء أو
          تأييد من قبلهم.
        </CopyrightPara>
        <CopyrightPara>
          مؤلفي بعض الأعمال المستخدمة في هذا الموقع الالكتروني والتي لا تخضع
          لحقوق النشر والتأليف لدى شركة Ayusha Opus Metal Art & Design Inc.
          وNilesh Bhathella . أو ممثليهم المعنيين ولديهم السلطة للقيام بما يلي:
        </CopyrightPara>
        <CopyrightPara>
          إنتاج، إعادة إنتاج أو نشر الأعمال الخاصة بهم أو أي جزء جوهري في أي
          نموذج مادة؛ تأدية الأعمال الخاصة بهم أو أي جزء جوهري في الأماكن
          العامة.
        </CopyrightPara>
        <CopyrightPara>
          إنتاج، إعادة إنتاج، تأدية أو نشر أي ترجمة من الأعمال الخاصة بهم.
        </CopyrightPara>
        <CopyrightPara>
          عمل أي تسجيل صوتي أو سينمائي لعملهم الأدبي أو الدرامي أو الموسيقي.
        </CopyrightPara>
        <CopyrightPara>
          إعادة إنتاج، تكييف، عرض أعمالهم الأدبية، الدرامية، الموسيقية أو الفنية
          الخاصة بهم على أنها عمل سينمائي.
        </CopyrightPara>
        <CopyrightPara>
          توصيل أعمالهم الأدبية، الدرامية، الموسيقية أو الفنية إلى العامة من
          خلال الاتصالات السلكية واللاسلكية.
        </CopyrightPara>
        <CopyrightPara>عرض أعمالهم الفنية في المعارض العامة.</CopyrightPara>
        <CopyrightPara>
          المؤلفون أو ممثلوهم مسؤولون كذلك عن الإذن بالإجراءات المذكورة.
        </CopyrightPara>

        <CopyrightSubHeading id='trademarks'>
          العلامات التجارية
        </CopyrightSubHeading>
        <CopyrightPara>
          وفقاً لقانون العلامات التجارية (R.S.C., 1985, c. T-13) وأي قوانين
          وأحكام تابعة للعلامات التجارية، الشعار AO وشركة Ayusha Opus Metal Art
          & Design Inc. علامات تجارية مسجلة ولا يجوز استخدامهم بأي شكل من
          الأشكال من قبل أي أشخاص و/أو شركات لأي أغراض. تم استخدام الشعار AO،
          Ayusha Opus، شركة Ayusha Opus Metal Art & Design Inc. وNilesh
          Bhathella في كافة أقسام الموقع الالكتروني باعتبارهم علامات تجارية
          مسجلة صراحةً ولا يجوز استخدامهم من قبل أي شخص يستخدم هذا الموقع.{' '}
        </CopyrightPara>
        <CopyrightPara>جميع الحقوق محفوظة ®AO </CopyrightPara>
        <CopyrightPara>جميع الحقوق محفوظة ™AO </CopyrightPara>
        <CopyrightPara>
          جميع الحقوق محفوظة ®AO Ayusha Opus Metal Art & Design Inc.
        </CopyrightPara>
        <CopyrightPara>
          جميع الحقوق محفوظة ™AO Ayusha Opus Metal Art & Design Inc.
        </CopyrightPara>

        <CopyrightSubHeading>إشعار العلامات التجارية</CopyrightSubHeading>
        <CopyrightPara>
          باستثناء أغراض تحديد المنتجات أو الخدمات، لا يتم منح أي حق أو ملكية أو
          مصلحة أو ترخيص في أي علامة تجارية أو علامة خدمة مانح الترخيص للموزع
          بموجب هذه الاتفاقية. يجوز للموزع أن يذكر في بطاقات العمل الخاصة به أنه
          موزع معتمد لترخيص المنتجات وتقديم خدمات المرخص فقط من خلال موافقة خطية
          من مالك العلامة التجارية (Ayusha Opus Metal Art & Design Inc. وNilesh
          Bhathella). لا يجوز للموزع الطعن في صحة هذه العلامات التجارية أو
          الملكية الحصرية لمانحي الترخيص. وخلال الفترة الزمنية لهذه الاتفاقية،
          لا يجوز للموزع اعتماد أو استخدام أو تسجيل عما إن كان اسم شركة، علامة
          تجارية، علامة خدمة، أي بيان أصل، أو أي من هذه العلامات، أي كلمة، أو
          علامة مشابهة تخلق لبساً في أي ولاية قضائية في جميع أنحاء العالم.
        </CopyrightPara>

        <CopyrightSubHeading>
          حيازة الملكية الفكرية – الأعمال الفنية
        </CopyrightSubHeading>
        <CopyrightPara>
          تمتلك شركة Ayusha Opus Metal Art & Design Inc. وNilesh Bhathella
          وحدهما جميع الحقوق، الممتلكات والمصالح، بما في ذلك كافة حقوق الملكية
          الفكرية ذات الصلة، في وإلى شركة Ayusha Opus Metal Art & Design Inc.،
          البيانات التقنية، المحتوى، الأعمال الفنية و/أو الخدمات، أي اقتراحات،
          أفكار، طلبات تحسين، تقييم، توصيات، معلومات أخرى تقدمها أنت أو أي طرف
          آخر فيما يتعلق بالعمل الفني و/أو الخدمات. هذه الاتفاقية ليست للبيع،
          ولا تنقل لك أي حقوق ملكية في أو فيما يتعلق بالفن و/ أو الخدمات وأي
          وكافة الأعمال الفنية و/ أو البيانات التقنية، وحقوق الملكية الفكرية
          مملوكة حصرياً لدى شركة Ayusha Opus Metal Art & Design Inc. وNilesh
          Bhathella. يعد اسم Ayusha Opus والشعار AO وأسماء المنتجات المرتبطة
          بالأعمال الفنية و/ أو الخدمات هي علامات تجارية لدى شركة Ayusha Opus
          Metal Art & Design Inc. وNilesh Bhathella ولا يتم منح أي حق أو ترخيص
          باستخدامها بأي شكل من الأشكال.{' '}
        </CopyrightPara>

        <CopyrightSubHeading>
          حيازة الملكية الفكرية – الموقع الالكتروني
        </CopyrightSubHeading>
        <CopyrightPara>
          يُعد الموقع الالكتروني والبرامج المرتبطة به ملكية فكرية وتعود ملكيتها
          إلى شركة AO® Ayusha Opus Metal Art & Design Inc. وNilesh Bhathella،
          يتضمن هيكل الموقع الالكتروني وتنظيمه وترميزه والبرامج المرتبطة به على
          أسرار تجارية قيمة ومعلومات سرية خاصة بشركة AO® Ayusha Opus Metal Art &
          Design Inc. وNilesh Bhathella باستثناء ما يُنص عليه هنا صراحةً. لا
          تمنحك هذه الاتفاقية أي حقوق ملكية فكرية على الإطلاق سواء في الموقع
          الالكتروني أو البرامج المرتبطة به وجميع الحقوق محفوظة لدى شركة AO®
          Ayusha Opus Metal Art & Design Inc. وNilesh Bhathella لأي نموذج، قاعدة
          بيانات، برنامج تم تغييره، تصوره، صنعه، أو تطويره جزئياً أو كلياً من
          قبل شركة AO® Ayusha Opus Metal Art & Design Inc. وNilesh Bhathella
          (بما في ذلك أعدت بصورة مشتركة) أثناء أو بفضل علاقتنا القائمة مع أية
          أشخاص و/أو شركات ينبغي أن يكونوا ويبقوا الملكية الوحيدة والحصرية لدى
          شركة AO® Ayusha Opus Metal Art & Design Inc. وNilesh Bhathella. أنت
          توافق على ألا تطالب بأي حقوق أو ملكية لأي نموذج، قاعدة بيانات، أو
          برنامج. إلى الحد الذي يتم فيه إنشاء نموذج مخصص من قبل شركة AO® Ayusha
          Opus Metal Art & Design Inc. وNilesh Bhathella خاص بك، بناءً على نموذج
          أو قالب مسبق مقدم من قبلك. تتضمن وتتعهد لشركة AO® Ayusha Opus Metal
          Art & Design Inc. وNilesh Bhathella أنك قمت بإنشاء النموذج (النماذج)
          المذكورة أو لديك الحق القانوني باستخدام النموذج (النماذج) المذكورة.
          أنت توافق على دفع تعويضات لشركة AO® Ayusha Opus Metal Art & Design
          Inc. وNilesh Bhathella عن أي مطالبات من الأطراف الثالثة بشأن
          المخالفات، سوء التصرف، أو أي انتهاكات أخرى لحقوق الملكية الفكرية لأي
          طرف ثالث حيث يتم تقديم هذه المطالبات ضد شركة Ayusha Opus Metal Art &
          Design Inc. وNilesh Bhathella لأي نموذج أو قالب تم إنشاؤه بناءً على
          المواد المزودة من قبلك إلى شركة AO® Ayusha Opus Metal Art & Design
          Inc. وNilesh Bhathella.{' '}
        </CopyrightPara>

        {/* <CopyrightSubHeading>In Short</CopyrightSubHeading>
                <CopyrightPara>What I am saying is:</CopyrightPara>
                <CopyrightPara>Do not copy any of my work in any way for any reason, that's it!</CopyrightPara>
                <CopyrightPara>Thank you ;)</CopyrightPara>
                <CopyrightPara>Sincerely,</CopyrightPara>
                <CopyrightPara>Nilesh Bhathella</CopyrightPara>
                <CopyrightPara>Ayusha Opus Metal Art & Design Inc.</CopyrightPara> */}

        {/* <CopyrightEndBorder /> */}
        <CopyrightHomeAccordian onCursor={onCursor} />
      </CopyrightMain>
    </ThemeProvider>
  );
}
