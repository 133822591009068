import React, { useRef, useState } from 'react';
import Jumbotron from '../../Components/jumbotron/index';
import { useGlobalStateContext } from '../../context/globalContext';
// import './index.scss';
import './index.css';
// import AO_Logo_on_black from '../../assets/img/meaning/AO_Logo_on_black.png';
// import AO_Logo_on_white from '../../assets/img/meaning/AO_Logo_on_white.png';
// import meaning1 from '../../assets/img/meaning/meaning1.jpg';

export function JumbotronContainer() {
  const [toggle, setToggle] = useState(false);
  const ripple = useRef(null);
  const AO_Logo_on_black =
    'https://ayushaopus.eu-central-1.linodeobjects.com/209167.d5800390.png';
  const AO_Logo_on_white =
    'https://ayushaopus.eu-central-1.linodeobjects.com/571096.2a5ee135.png';
  const meaning1 =
    'https://ayushaopus.eu-central-1.linodeobjects.com/851907.b0baae57.jpg';

  setTimeout(function () {
    // toggle && console.log('Delayed True')
    toggle && setToggle(!toggle);
  }, 500);

  const { currentTheme } = useGlobalStateContext();
  return (
    <div>
      <div className='Mobile-Container'>
        {/* Jumbotron 1 */}
        <div className='Jumbo'>
          <div className='meaning1'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    ماذا يعني "Ayusha Opus" وما هو السبب وراء اختيار هذا الاسم
                    ليمثل ما أقوم بصناعته؟
                  </p>
                  <p>
                    هذا الاسم هو تجميع لبعض الكلمات الخاصة جداً، حيث تترادف
                    معانيه مع الفن الذي نقوم بإنتاجه.
                  </p>
                  <p>
                    "Ayusha" يقصد به "العمر، فترة الحياة والسلالة"، ترجع أصول
                    هذا الاسم إلى اللغة السنسكريتية؛ مع ذلك في اللغة العربية
                    معناه " الشخص الذي ينعم بحياة جيدة، شخص لديه حياة كريمة تنعم
                    بالرخاء ومليئة بالتفاؤل والأمل.
                  </p>
                  <p>
                    أوبوس "Opus" معناه "العمل الفني، وخاصة العمل الذي يكون على
                    نطاق واسع".
                  </p>
                </Jumbotron.Text>
              </div>
              <div>
                {/* <br /><br /> */}
                {/* <Jumbotron.Image
                  className='AO_LOGO_MEANING'
                  src={
                    currentTheme === 'dark'
                      ? require('../../assets/img/meaning/AO_Logo_on_black.png')
                        .default
                      : require('../../assets/img/meaning/AO_Logo_on_white.png')
                        .default
                  }
                  alt=''
                /> */}
                <div class={`hover15 columnss`}>
                  <div>
                    <figure
                      ref={ripple}
                      onClick={() => {
                        setToggle(!toggle);
                      }}
                      className={`${toggle && 'rippleForMobile'}`}
                    >
                      <img
                        className='AO_LOGO_MEANING'
                        src={
                          currentTheme === 'dark'
                            ? AO_Logo_on_black
                            : // ? 'https://ayushaopus.eu-central-1.linodeobjects.com/209167.d5800390.png'
                              AO_Logo_on_white
                          // : 'https://ayushaopus.eu-central-1.linodeobjects.com/571096.2a5ee135.png'
                        }
                        alt=''
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    </figure>
                  </div>
                  <div className='dontshow'>
                    <figure>
                      <img
                        className='AO_LOGO_MEANING'
                        src={
                          currentTheme === 'dark'
                            ? AO_Logo_on_black
                            : // ? 'https://ayushaopus.eu-central-1.linodeobjects.com/209167.d5800390.png'
                              AO_Logo_on_white
                          // : 'https://ayushaopus.eu-central-1.linodeobjects.com/571096.2a5ee135.png'
                        }
                        alt=''
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    </figure>
                  </div>
                  <div className='dontshow'>
                    <figure>
                      <img
                        className='AO_LOGO_MEANING'
                        src={
                          currentTheme === 'dark'
                            ? AO_Logo_on_black
                            : // ? 'https://ayushaopus.eu-central-1.linodeobjects.com/209167.d5800390.png'
                              AO_Logo_on_white
                          // : 'https://ayushaopus.eu-central-1.linodeobjects.com/571096.2a5ee135.png'
                        }
                        alt=''
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    </figure>
                  </div>
                </div>
                {/* <br /><br />    <br />    <br /> */}
                {/* <div className='overlay'></div> */}
              </div>
            </div>
          </div>

          {/* Jumbotron 2 */}
          <div className='meaning2'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    اخترت أن نضع هذه الكلمات الخاصة جداً بأصولها ومعانيها مع
                    بعضها البعض لكي تدل على العظمة الفنية لما أبدعناه، علماً
                    بأنه سترمز إلى نجاحك الابداعي وسوف تتوارث عبر الأجيال أو
                    ستكون هدية ثمينة نفيسة تقدمها لمن ترغب باختياره.
                  </p>
                  <p>
                    الجوهر من هذا التجميع الرائع يكمن في كل شيء نقوم به ~ألفا
                    وأوميجا ~ من البداية حتى النهاية.
                  </p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Image
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/meaning1.jpg'
                  // src = 'https://ayushaopus.eu-central-1.linodeobjects.com/851907.b0baae57.jpg'
                  src={meaning1}
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='Desktop-Container'>
        {/* Jumbotron 1 */}
        <div className='Jumbo'>
          {/* Jumbotron 1 */}
          <Jumbotron.Container className='meaning1'>
            <Jumbotron direction='row'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    ماذا يعني "Ayusha Opus" وما هو السبب وراء اختيار هذا الاسم
                    ليمثل ما أقوم بصناعته؟
                  </p>
                  <p>
                    هذا الاسم هو تجميع لبعض الكلمات الخاصة جداً، حيث تترادف
                    معانيه مع الفن الذي نقوم بإنتاجه.
                  </p>
                  <p>
                    "Ayusha" يقصد به "العمر، فترة الحياة والسلالة"، ترجع أصول
                    هذا الاسم إلى اللغة السنسكريتية؛ مع ذلك في اللغة العربية
                    معناه " الشخص الذي ينعم بحياة جيدة، شخص لديه حياة كريمة تنعم
                    بالرخاء ومليئة بالتفاؤل والأمل.
                  </p>
                  <p>
                    أوبوس "Opus" معناه "العمل الفني، وخاصة العمل الذي يكون على
                    نطاق واسع".
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <div className='full-landing-image'></div>
                {/* <Jumbotron.Image
                  className='AO_LOGO_MEANING full-landing-image'
                  src={
                    currentTheme === 'dark'
                      ? require('../../assets/img/meaning/AO_Logo_on_black.png')
                          .default
                      : require('../../assets/img/meaning/AO_Logo_on_white.png')
                          .default
                  }
                  alt=''
                /> */}

                <div class='hover15 columnss'>
                  <div>
                    <figure>
                      <img
                        className='AO_LOGO_MEANING'
                        src={
                          currentTheme === 'dark'
                            ? AO_Logo_on_black
                            : // ? 'https://ayushaopus.eu-central-1.linodeobjects.com/209167.d5800390.png'
                              AO_Logo_on_white
                          // : 'https://ayushaopus.eu-central-1.linodeobjects.com/571096.2a5ee135.png'
                        }
                        alt=''
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    </figure>
                  </div>
                  <div className='dontshow'>
                    <figure>
                      <img
                        className='AO_LOGO_MEANING'
                        src={
                          currentTheme === 'dark'
                            ? AO_Logo_on_black
                            : // ? 'https://ayushaopus.eu-central-1.linodeobjects.com/209167.d5800390.png'
                              AO_Logo_on_white
                          // : 'https://ayushaopus.eu-central-1.linodeobjects.com/571096.2a5ee135.png'
                        }
                        alt=''
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    </figure>
                  </div>
                  <div className='dontshow'>
                    <figure>
                      <img
                        className='AO_LOGO_MEANING'
                        src={
                          currentTheme === 'dark'
                            ? AO_Logo_on_black
                            : // ? 'https://ayushaopus.eu-central-1.linodeobjects.com/209167.d5800390.png'
                              AO_Logo_on_white
                          // : 'https://ayushaopus.eu-central-1.linodeobjects.com/571096.2a5ee135.png'
                        }
                        alt=''
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    </figure>
                  </div>
                </div>
                {/* <div className='overlay'></div> */}
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>

          {/* Jumbotron 2 */}
          <Jumbotron.Container className='meaning2'>
            <Jumbotron direction='row-reverse'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    اخترت أن نضع هذه الكلمات الخاصة جداً بأصولها ومعانيها مع
                    بعضها البعض لكي تدل على العظمة الفنية لما أبدعناه، علماً
                    بأنه سترمز إلى نجاحك الابداعي وسوف تتوارث عبر الأجيال أو
                    ستكون هدية ثمينة نفيسة تقدمها لمن ترغب باختياره.
                  </p>
                  <p>
                    الجوهر من هذا التجميع الرائع يكمن في كل شيء نقوم به ~ألفا
                    وأوميجا ~ من البداية حتى النهاية.
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/meaning1.jpg'
                  // src = 'https://ayushaopus.eu-central-1.linodeobjects.com/851907.b0baae57.jpg'
                  src={meaning1}
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>
        </div>
      </div>
    </div>
  );
}
