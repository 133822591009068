import styled, { css } from 'styled-components/macro';
import { motion } from 'framer-motion';

export const FooterNav = styled(motion.div)`
  // height: 300px;
  // margin-top: 296px;
  height: 60px;
  // margin-top: 70px;
  margin-top: -35px;
  margin-bottom: 70px;
  //border:1px solid white;
  width:100%;
  @media (max-width: 460px) {
    height: 180px;
    @media (max-width: 460px) {
      // margin-top: 80px;
      margin: -30px 0 0 0;
    }
    .flex {
      flex-direction: column;
    }

    .flex-child {
      width: 100%;
      margin-bottom: 20px;
      @media (max-width: 460px) {
        display: flex;
        flex-direction: column;
        align-items: baseline;
      }
    }
  }
`;

export const FooterContent = styled.div`
  color: #eb002a;
  font-size: 22px;
  font-weight: 600;
  line-height: 8px;
  width: fit-content;
  flex: 1;
  ${(props) =>
    props.wider &&
    css`
      flex: 2;
      @media (max-width: 768px) {
        flex: 1;
      }
    `}
`;

export const FooterSocial = styled.div`
  display: flex;
  //border:1px solid white;
  @media (max-width: 460px) {
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 60px !imoprtant;
  }

  a {
    position: relative;
    display: block;
    width: 24px;
    height: 24px;
    // padding: 8px;
    padding-left: 16px;

    &.facebook {
      padding-left: 0;
      margin-left: -5px;
    }

    svg {
      width: 100%;
      height: 100%;
    }
    svg:hover {
      path {
        fill: ${(props) => props.theme.text};
      }
      g {
        fill: ${(props) => props.theme.text};
      }
    }
  }
`;
